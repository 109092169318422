import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "../components/layout.scss";
import Layout from "../components/layout";
import styled from "styled-components";

const TextBlock = styled.div`
  text-align: center;
`;

const ImageBlock = styled.div`
  text-align: center;
  margin-top: -20px;
  margin-bottom: 40px;
`;

const ContentBlock = styled.div`
  margin: 40px;
  text-align: center;
`;

const ExtraPaddingBottom = styled.div`
  padding-bottom: 10px;
`;

const AboutUs = () => {
  return (
    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-18"
                    class="post-18 page type-page status-publish hentry"
                  >
                    <div class="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="18"
                        class="elementor elementor-18"
                        data-elementor-settings="[]"
                      >
                        <div class="elementor-section-wrap">
                          <section
                            class="elementor-section elementor-top-section elementor-element elementor-element-69791ad elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="69791ad"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div class="elementor-container elementor-column-gap-default ">
                              <div
                                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8f1c281"
                                data-id="8f1c281"
                                data-element_type="column"
                              >
                                <div class="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    class="elementor-element elementor-element-52bae3da primary-color elementor-widget elementor-widget-cms_heading"
                                    data-id="52bae3da"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <ExtraPaddingBottom />

                                    <div class="">
                                      <div class="cms-heading-wrapper cms-heading-layout1 ">
                                        <h3 class="custom-heading">
                                          <span>
                                            Effective date: January 1, 2021
                                          </span>
                                        </h3>
                                      </div>{" "}
                                    </div>
                                  </div>

                                  <div
                                    class="elementor-element elementor-element-7893195d elementor-widget elementor-widget-cms_heading"
                                    data-id="7893195d"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div class="">
                                      <div class="cms-heading-wrapper cms-heading-layout1 ">
                                        <h3 class="custom-heading">
                                          <span>Terms of Use</span>
                                        </h3>
                                        Toddler Talk Terms of Use Effective
                                        date: January 1, 2021
                                        <div class="custom-heading-description mb-3">
                                          These terms of use and our Privacy
                                          Policy, which is incorporated herein
                                          by reference (collectively, “Terms of
                                          Use”), govern the relationship between
                                          you and Toddler Talk, Inc., a
                                          California corporation dba Toddler
                                          Talk, and its parents, subsidiaries,
                                          and affiliates (collectively, “Toddler
                                          Talk” or “we” or “us”) relating in any
                                          way to your use of any online, mobile,
                                          or downloadable product or service to
                                          which you have access, any product or
                                          service that requires an internet
                                          connection or Toddler Talk account to
                                          access, install, use, or play, any
                                          Toddler Talk account you use to access
                                          online or mobile products or services,
                                          and any Toddler Talk website,
                                          including but not limited to{" "}
                                          <a
                                            href="https://www.toddlertalk.com/"
                                            target="_blank"
                                          >
                                            https://www.toddlertalk.com/
                                          </a>
                                          ,{" "}
                                          <a
                                            href="https://www.toddlertalkSLP.com/"
                                            target="_blank"
                                          >
                                            https://www.toddlertalkSLP.com/
                                          </a>
                                          , and any service accessible from an
                                          Toddler Talk webpage (collectively,
                                          the “Toddler Talk Services” and
                                          individually a “Toddler Talk Service”)
                                          offered by Toddler Talk. Each Toddler
                                          Talk Service may have its own specific
                                          Terms of Use and Privacy Policy, and
                                          if you download, access, or use a
                                          Toddler Talk Service, you agree to
                                          also be bound by its specific Terms of
                                          Use and Privacy Policy.
                                          <ExtraPaddingBottom />
                                          <b>
                                            SECTION 12 OF THESE TERMS OF USE
                                            CONTAINS A BINDING ARBITRATION
                                            AGREEMENT AND CLASS ACTION WAIVER,
                                            WHICH AFFECT YOUR LEGAL RIGHTS.
                                            PLEASE READ IT CAREFULLY.
                                          </b>
                                          <ExtraPaddingBottom />
                                          <b>
                                            1. YOUR ACCEPTANCE OF THESE TERMS OF
                                            USE
                                          </b>
                                          <ExtraPaddingBottom />
                                          These Terms of Use form a legal
                                          agreement between you and Toddler
                                          Talk. When you use a Toddler Talk
                                          Service, you agree to be bound by all
                                          of the terms, conditions, and
                                          restrictions in these Terms of Use.
                                          Please read them carefully.
                                          <ExtraPaddingBottom />
                                          You acknowledge that you may access
                                          and/or download some Toddler Talk
                                          Services for free, and while there may
                                          be additional content or services
                                          available for purchase within some
                                          Toddler Talk Services (“Toddler Talk
                                          Purchases”), such Toddler Talk
                                          Purchases are entirely voluntary and
                                          not required to participate in or
                                          advance within Toddler Talk Services.
                                          You also acknowledge that you may not
                                          access and/or download other Toddler
                                          Talk Services without executing a
                                          separate agreement with Toddler Talk
                                          which may require you to pay for
                                          content or services (“Service
                                          Charges”).
                                          <ExtraPaddingBottom />
                                          <b>
                                            2. LIMITED LICENSE, LOGIN
                                            INFORMATION, AND YOUR ACCOUNT AND
                                            ELIGIBILITY.
                                          </b>
                                          <ExtraPaddingBottom />
                                          <b>Limited License Grant</b>
                                          <ExtraPaddingBottom />
                                          Subject to your agreement and
                                          continuing compliance with these Terms
                                          of Use and Toddler Talk’s policies,
                                          Toddler Talk grants you a
                                          non-exclusive, non-transferable,
                                          non-sublicensable, revocable limited
                                          license subject to the limitations
                                          below to access and use Toddler Talk
                                          Services. Unless otherwise specified,
                                          Toddler Talk Services are for your
                                          personal and non-commercial use.
                                          Unless otherwise specified, you may
                                          not modify, copy, distribute,
                                          transmit, display, perform, reproduce,
                                          publish, license, create derivative
                                          works from, transfer, or sell any
                                          information, software, products or
                                          services obtained from Toddler Talk
                                          Services.
                                          <ExtraPaddingBottom />
                                          <b>
                                            Login Information and Your Toddler
                                            Talk Account
                                          </b>
                                          <ExtraPaddingBottom />
                                          You may create an account with us
                                          (“Toddler Talk Account”) by providing
                                          a username and/or email address and a
                                          password (“Login Information”). With
                                          some Toddler Talk Services, we may
                                          also allow you to create an account
                                          through third party social networks or
                                          services (such as Facebook) with whom
                                          you maintain an account (a “Third
                                          Party Service”).
                                          <ExtraPaddingBottom />
                                          <b>
                                            You shall not share your Toddler
                                            Talk Account or your Login
                                            Information, nor let anyone else
                                            access your account or do anything
                                            else that might jeopardize the
                                            security of your account.
                                          </b>{" "}
                                          You are solely responsible for
                                          maintaining the confidentiality of
                                          your Login Information, and you will
                                          be solely responsible for all uses of
                                          your Login Information, including any
                                          purchases, Toddler Talk Purchases,
                                          Service Charges, or other changes to
                                          your Toddler Talk Account and/or
                                          Toddler Talk Account status, whether
                                          or not authorized by you. Toddler Talk
                                          will not be responsible for anything
                                          that happens through or to your
                                          Toddler Talk Account and/or Toddler
                                          Talk Account status as a result of you
                                          allowing any third party to access
                                          your Login Information and/or Toddler
                                          Talk Account.
                                          <ExtraPaddingBottom />
                                          Toddler Talk reserves the right to
                                          remove or reclaim any usernames at any
                                          time and for any reason, including but
                                          not limited to claims by a third party
                                          that a username violates the third
                                          party’s rights.
                                          <ExtraPaddingBottom />
                                          <b>Eligibility</b>
                                          <ExtraPaddingBottom />
                                          There are limitations on who may
                                          download and access Toddler Talk
                                          Services.{" "}
                                          <b>
                                            You may not download or access
                                            Toddler Talk Services if you are
                                            under 13 years of age.
                                          </b>{" "}
                                          If we learn that a user under 13 years
                                          of age is accessing a Toddler Talk
                                          Service despite this restriction, we
                                          will immediately delete their account
                                          and all personal information we have
                                          related to their account.
                                          <ExtraPaddingBottom />
                                          You also may not use Toddler Talk
                                          Services if: (i) you cannot enter into
                                          a binding contract with us and your
                                          legal guardian has not agreed to these
                                          Terms of Use on your behalf; (ii) you
                                          are a convicted sex offender; or (iii)
                                          we have previously banned you from
                                          using a Toddler Talk Service.
                                          <ExtraPaddingBottom />
                                          If you are between the ages of 13 and
                                          17, or under the age of majority where
                                          you live, you may not use Toddler Talk
                                          Services unless your parent or legal
                                          guardian has reviewed and agreed to
                                          these Terms of Use and the specific
                                          Terms of Use for that Toddler Talk
                                          Service.
                                          <ExtraPaddingBottom />
                                          <b>3. MODIFICATION OF TERMS OF USE</b>
                                          <ExtraPaddingBottom />
                                          We reserve the right to modify these
                                          Terms of Use from time to time by
                                          posting updated versions in Toddler
                                          Talk Services or otherwise notifying
                                          you of changes through Toddler Talk
                                          Services. Unless we state otherwise,
                                          changes are effective when we post or
                                          notify you of them. By continuing to
                                          use a Toddler Talk Service after the
                                          posting of changes to these Terms of
                                          Use, you agree to the changes and the
                                          updated Terms of Use.
                                          <ExtraPaddingBottom />
                                          <b>4. NO TRANSFER OF ACCOUNTS</b>
                                          <ExtraPaddingBottom />
                                          Unless otherwise specified, your
                                          Toddler Talk Account is for your
                                          personal, non-commercial use only.{" "}
                                          <b>
                                            You specifically agree not to: (1)
                                            share your account credentials with
                                            anyone else, (2) sell, offer to
                                            sell, rent, lease, trade or
                                            otherwise transfer your account, (3)
                                            sell, offer to sell, rent, lease,
                                            trade or otherwise transfer any
                                            content, (4) buy, offer to buy,
                                            accept, access or use any other
                                            user’s account, and (5) link to or
                                            otherwise endorse or identify any
                                            websites, organizations, or persons
                                            that sell, offer to sell, rent,
                                            lease, buy, offer to buy, accept,
                                            access, trade or otherwise transfer
                                            accounts.
                                          </b>
                                          <ExtraPaddingBottom />
                                          You acknowledge that any purported
                                          transfers of Toddler Talk Service
                                          Accounts or In-Service Content in
                                          violation of this Section, including
                                          but not limited to transfers in
                                          exchange for money or other
                                          compensation, are void and breach
                                          these Terms of Use, and that we have
                                          no responsibility to assist you in
                                          connection with completing any such
                                          transaction. We may terminate or
                                          suspend your Toddler Talk Account
                                          and/or your right to access or use
                                          Toddler Talk Services if we discover
                                          or reasonably believe that you have
                                          attempted to buy, sell, rent, lease,
                                          license, or transfer your Toddler Talk
                                          Account or associated In-Service
                                          Content, or used or accessed an
                                          account or In-Services Content
                                          transferred by another user, or linked
                                          to or otherwise endorsed or identified
                                          any websites, organizations, or
                                          persons that attempt to buy, sell,
                                          rent, lease, license, or transfer
                                          accounts or In-Service Content in
                                          violation of this Section.
                                          <ExtraPaddingBottom />
                                          <b>
                                            5. INTELLECTUAL PROPERTY RIGHTS IN
                                            THE SERVICES
                                          </b>
                                          <ExtraPaddingBottom />
                                          Except for the limited license granted
                                          herein, we and our licensors retain
                                          all right, title and interest in and
                                          to Toddler Talk Services, including
                                          all content made available through
                                          Toddler Talk Services and the
                                          technology and software used to
                                          provide Toddler Talk Services. Toddler
                                          Talk Services are protected by
                                          copyright, trademark and other laws.
                                          If you violate these Terms of Use, you
                                          may be breaking the law, including by
                                          violating our intellectual property
                                          rights. We and our licensors may
                                          actively protect our intellectual
                                          property rights in the event you
                                          violate these Terms of Use. In
                                          addition, you agree not to modify,
                                          create derivative works of, decompile,
                                          or otherwise attempt to extract source
                                          code from us.
                                          <ExtraPaddingBottom />
                                          We have the right to use clips or
                                          screen shots from your use of the
                                          Services however we like, including,
                                          but not limited to, on our social
                                          media channels, marketing, and
                                          advertising.
                                          <ExtraPaddingBottom />
                                          If you provide us with any suggestions
                                          for enhancement or feedback regarding
                                          Toddler Talk Services or any of our
                                          other products or services, you agree
                                          that we will have a perpetual,
                                          transferable, sub-licensable,
                                          royalty-free, irrevocable, worldwide
                                          license to use such suggestions and
                                          feedback, including by incorporating
                                          your suggestions or feedback in
                                          Toddler Talk Services, without any
                                          obligation to compensate you.
                                          <ExtraPaddingBottom />
                                          <b>6. CODE OF CONDUCT</b>
                                          <ExtraPaddingBottom />
                                          You agree to comply with the following
                                          requirements (our "Code of Conduct")
                                          when you access Toddler Talk Services:
                                          <ul>
                                            <li>
                                              You will comply with your
                                              obligations to third party mobile
                                              platform providers, such as Apple
                                              and Google, from whom you download
                                              a Toddler Talk Service, including
                                              any terms regarding payment for
                                              Toddler Talk Purchases and Service
                                              Charges made through such
                                              platforms.
                                            </li>
                                            <li>
                                              You will not attempt to obtain
                                              login information or access
                                              another user’s account or collect
                                              or harvest personal information
                                              from other users.
                                            </li>
                                            <li>
                                              You will not exploit, distribute
                                              or publicly inform other users of
                                              any Toddler Talk Service error,
                                              miscue, or bug which provides for
                                              an unintended advantage or allows
                                              impersonation of another person,
                                              including by use of automated
                                              collection mechanisms such as
                                              clear graphics interchange formats
                                              ("gifs"), cookies, or similar
                                              technology.
                                            </li>
                                            <li>
                                              You will not exploit differences
                                              in pricing for Toddler Talk
                                              Purchases or In-Service Content
                                              (e.g., through arbitrage) by
                                              sending altered, deceptive, or
                                              false source-identifying
                                              information, including but not
                                              limited to, by use of a proxy or
                                              spoofed IP addresses or VPNs, or
                                              by requesting that someone else
                                              make Toddler Talk Purchases or
                                              purchase In-Service Content on
                                              your behalf in order to take
                                              advantage of a difference in
                                              pricing.
                                            </li>
                                            <li>
                                              You will not harass, threaten or
                                              bully any other users, nor post or
                                              submit any abusive, threatening,
                                              bullying, harassing, obscene,
                                              defamatory, offensive,
                                              pornographic or illegal content,
                                              or content that infringes or
                                              violates the rights of someone
                                              else, or impersonate any other
                                              person.
                                            </li>
                                            <li>
                                              You will not use Toddler Talk
                                              Services for any unauthorized
                                              commercial purpose, including but
                                              not limited to commercial
                                              advertisement or solicitation
                                              (such as chain letters, junk
                                              e-mail, “spam,” or other
                                              repetitive messages), or gathering
                                              or transferring In-Service Content
                                              for sale.
                                            </li>
                                            <li>
                                              You will not use Toddler Talk
                                              Services for performing services
                                              for payment, such as leveling up
                                              or item collection services.
                                            </li>
                                            <li>
                                              You will not use or design cheats,
                                              exploits, automation software,
                                              bots, hacks, mods or any
                                              unauthorized software designed to
                                              modify or interfere with a Toddler
                                              Talk Service experience.
                                            </li>
                                            <li>
                                              You will not remove or obscure any
                                              proprietary notices within Toddler
                                              Talk Services.
                                            </li>
                                            <li>
                                              You will not disrupt or attempt to
                                              disrupt Toddler Talk Services or
                                              any other person’s use or
                                              enjoyment of Toddler Talk
                                              Services.
                                            </li>
                                            <li>
                                              You will not attempt to gain
                                              unauthorized access to Toddler
                                              Talk Services, to accounts
                                              registered to others, or to
                                              networks from which portions of
                                              Toddler Talk Services are
                                              provided, including by
                                              circumventing or bypassing robot
                                              exclusion headers or security
                                              mechanisms for Toddler Talk
                                              Services.
                                            </li>
                                            <li>
                                              You will not use any service or
                                              software that accesses,
                                              intercepts, “mines”, or otherwise
                                              collects information from Toddler
                                              Talk Services or that is in
                                              transit from or to Toddler Talk
                                              Services.
                                            </li>
                                            <li>
                                              You will not make any automated
                                              use of Toddler Talk Services, or
                                              take any action that imposes an
                                              unreasonable burden on our
                                              infrastructure.
                                            </li>
                                            <li>
                                              You will not use any software,
                                              technology, or device to send
                                              content or messages, scrape,
                                              spider, or crawl Toddler Talk
                                              Services, or harvest or manipulate
                                              data from Toddler Talk Services.
                                            </li>
                                            <li>
                                              You will not improperly use our
                                              support services, including by
                                              submitting false reports.
                                            </li>
                                            <li>
                                              Except where permitted by law or
                                              relevant open source licenses, you
                                              will not modify, reverse engineer,
                                              decompile, disassemble, decipher
                                              or otherwise try to derive the
                                              source code for Toddler Talk
                                              Services.
                                            </li>
                                            <li>
                                              You will not copy, modify or
                                              distribute content from Toddler
                                              Talk Services except as
                                              specifically authorized by us.
                                            </li>
                                            <li>
                                              You will not advocate, promote,
                                              encourage, or otherwise facilitate
                                              any illegal behavior, including
                                              but not limited to the use of
                                              illegal drugs, unlawful gambling
                                              or theft.
                                            </li>
                                          </ul>
                                          <ExtraPaddingBottom />
                                          <b>
                                            Failure to comply with this Code of
                                            Conduct may result in the suspension
                                            of your Toddler Talk Account, or any
                                            portion of your Toddler Talk
                                            Account, and/or subject you to any
                                            of the penalties identified in
                                            Section 9 herein.
                                          </b>
                                          <ExtraPaddingBottom />
                                          <b>
                                            7. YOUR INTERACTIONS WITH OTHER
                                            USERS
                                          </b>
                                          <ExtraPaddingBottom />
                                          You are solely responsible for your
                                          interactions with other users with
                                          whom you interact through Toddler Talk
                                          Services you access. Toddler Talk
                                          reserves the right, but has no
                                          obligation, to become involved in any
                                          way with any disputes concerning
                                          Toddler Talk Services. You will fully
                                          cooperate with us to investigate any
                                          suspected unlawful, fraudulent or
                                          improper activity, including without
                                          limitation granting Toddler Talk
                                          access to any portion of your account.
                                          <ExtraPaddingBottom />
                                          If you have a dispute with one or more
                                          users, or any other interaction you
                                          believe may give rise to any claim,
                                          you release Toddler Talk (and our
                                          officers, directors, agents,
                                          subsidiaries, joint ventures and
                                          employees) from claims, demands and
                                          damages (actual and consequential) of
                                          every kind and nature, known and
                                          unknown, arising out of or in any way
                                          connected with such disputes.
                                          <ExtraPaddingBottom />
                                          <b>
                                            If you live in California, you waive
                                            California Civil Code §1542, which
                                            says: "A general release does not
                                            extend to claims which the creditor
                                            does not know or suspect to exist in
                                            his or her favor at the time of
                                            executing the release, which if
                                            known by him or her must have
                                            materially affected his settlement
                                            with the debtor."
                                          </b>
                                          <ExtraPaddingBottom />
                                          <b>
                                            If you live outside of California,
                                            you waive any other statute, law, or
                                            rule of similar effect in your
                                            jurisdiction.
                                          </b>
                                          <ExtraPaddingBottom />
                                          <b>
                                            8. INFORMATION COLLECTION AND USE;
                                            PRIVACY POLICY
                                          </b>
                                          <ExtraPaddingBottom />
                                          In addition to User Content, Toddler
                                          Talk collects (i) information, content
                                          or other material that you provide to
                                          us or is otherwise transmitted in or
                                          through Toddler Talk Services you
                                          access or which is generated, acquired
                                          or developed when you download,
                                          install, and/or use Toddler Talk
                                          Services or anytime you are in contact
                                          with us and (ii) information you
                                          receive or which is transmitted to or
                                          otherwise made available to you from
                                          other users of Toddler Talk Services
                                          or third parties in Toddler Talk
                                          Services (collectively, “Service
                                          Information”). We use the Service
                                          Information in many ways, including
                                          but not limited to, to help us provide
                                          and support Toddler Talk Services and
                                          our services. For example, we may use
                                          the Service Information to deliver and
                                          target advertising and generate
                                          information about the reach and
                                          effectiveness of advertising through
                                          various measurements and analytics
                                          (without providing personally
                                          identifiable information about you).
                                          <ExtraPaddingBottom />
                                          Our Privacy Policy sets out detailed
                                          information regarding our collection,
                                          use and sharing of information from
                                          and about you. When you download or
                                          access a Toddler Talk Service, you
                                          acknowledge and accept that our
                                          general and that Toddler Talk
                                          Service’s specific Privacy Policy
                                          applies to you. You should carefully
                                          review such Privacy Policies and, if
                                          you do not agree with them, you should
                                          not use Toddler Talk Services.
                                          <ExtraPaddingBottom />
                                          <b>9. TERMINATION AND SUSPENSION</b>
                                          <ExtraPaddingBottom />
                                          You may terminate your Toddler Talk
                                          Account at any time, for any reason or
                                          no reason, by issuing an in-Service
                                          message or by sending an email to
                                          support@toddlertalk.com with the
                                          subject entitled “Termination.”
                                          <ExtraPaddingBottom />
                                          We reserve the right to suspend or
                                          discontinue Toddler Talk Services or
                                          any part of them at any time,
                                          including any features, elements and
                                          In-Service Content and revoke any and
                                          all licenses provided to you under
                                          these Terms of Use. If that happens,
                                          we are not required to provide
                                          refunds, benefits or other
                                          compensation to you for discontinued
                                          elements of Toddler Talk Services or
                                          for any In-Service Content previously
                                          earned or purchased, and you will have
                                          no further right to access your
                                          account or use Toddler Talk Services.
                                          WE MAY, IN OUR SOLE DISCRETION, LIMIT,
                                          SUSPEND, TERMINATE, MODIFY, OR DELETE
                                          ACCOUNTS OR ACCESS TO TODDLER TALK
                                          SERVICES OR ANY PORTION OF THEM, AND
                                          PROHIBIT ACCESS TO TODDLER TALK
                                          SERVICES AND THE CONTENT, SERVICES AND
                                          TOOLS AVAILABLE THROUGH TODDLER TALK
                                          SERVICES, OR DELAY OR REMOVE USER
                                          CONTENT, AND WE ARE UNDER NO
                                          OBLIGATION TO COMPENSATE YOU FOR ANY
                                          LOSSES OR RESULTS.
                                          <ExtraPaddingBottom />
                                          Upon termination of your account for
                                          any reason, you agree to no longer
                                          access (or attempt to access) Toddler
                                          Talk Services.
                                          <ExtraPaddingBottom />
                                          <b>
                                            10. DISCLAIMER; LIMITATION OF
                                            LIABILITY
                                          </b>
                                          <ExtraPaddingBottom />
                                          TODDLER TALK SERVICES ARE PROVIDED "AS
                                          IS," AND USE OF TODDLER TALK SERVICES
                                          IS AT YOUR SOLE RISK. TO THE EXTENT
                                          PERMITTED BY APPLICABLE LAW, WE MAKE
                                          NO REPRESENTATIONS, WARRANTIES, OR
                                          CONDITIONS OF ANY KIND, EITHER EXPRESS
                                          OR IMPLIED, ABOUT TODDLER TALK
                                          SERVICES OR ANY CONTENT OR SERVICES
                                          MADE AVAILABLE THROUGH TODDLER TALK
                                          SERVICES, AND WE SPECIFICALLY DISCLAIM
                                          ANY IMPLIED WARRANTIES OF
                                          MERCHANTABILITY, FITNESS FOR A
                                          PARTICULAR PURPOSE, NON-INFRINGEMENT,
                                          OR AVAILABILITY. TODDLER TALK DOES NOT
                                          WARRANT THAT YOU WILL BE ABLE TO
                                          ACCESS OR USE TODDLER TALK SERVICES AT
                                          THE TIMES OR LOCATIONS OF YOUR
                                          CHOOSING; THAT TODDLER TALK SERVICES
                                          WILL BE UNINTERRUPTED OR ERROR-FREE;
                                          THAT DEFECTS WILL BE CORRECTED; OR
                                          THAT TODDLER TALK SERVICES OR OUR
                                          SERVICE ARE FREE OF VIRUSES OR OTHER
                                          HARMFUL COMPONENTS.
                                          <ExtraPaddingBottom />
                                          ALL CONTENT FOUND IN THE TODDLER TALK
                                          SERVICES IS FOR EDUCATIONAL PURPOSES
                                          ONLY. TODDLER TALK MAKES NO
                                          WARRANTIES, EXPRESS OR IMPLIED, ABOUT
                                          THE ACCURACY OF ANY CONTENT FOUND IN
                                          THE TODDLER TALK SERVICES, OR ABOUT
                                          THE OUTCOME OF YOUR USE OF THE
                                          INFORMATION FOUND THEREIN. TODDLER
                                          TALK IS NOT A LICENSED SPEECH
                                          PATHOLOGY SERVICE. NEITHER TODDLER
                                          TALK NOR STEPHANIE KEFFER PROVIDE ANY
                                          SPEECH PATHOLOGY SERVICES THROUGH THE
                                          TODDLER TALK SERVICES. USE OF ANY
                                          INFORMATION OR CONTENT FOUND IN THE
                                          TODDLER TALK SERVICES IS AT THE USER’S
                                          OWN RISK. USERS ARE STRONGLY ADVISED
                                          TO CONSULT A LICENSED SPEECH
                                          PATHOLOGIST.
                                          <ExtraPaddingBottom />
                                          YOU ACKNOWLEDGE THAT, EXCEPT IN THE
                                          CASE OF WILLFUL ACTS OR GROSS
                                          NEGLIGENCE, WE WILL NOT BE LIABLE TO
                                          YOU FOR: (1) ANY INCIDENTAL, INDIRECT,
                                          PUNITIVE, SPECIAL OR CONSEQUENTIAL
                                          DAMAGES, REGARDLESS OF THE CAUSE OF
                                          ACTION AND EVEN IF WE HAVE BEEN
                                          ADVISED OF THE POSSIBILITY OF SUCH
                                          DAMAGES; (2) AN AMOUNT IN EXCESS OF
                                          $100 OR THE AMOUNTS YOU HAVE PAID US
                                          FOR TODDLER TALK SERVICES DURING THE 6
                                          MONTH PERIOD PRECEDING THE EVENT FIRST
                                          GIVING RISE TO LIABILITY, WHICHEVER IS
                                          GREATER; OR (3) THE CONDUCT OF THIRD
                                          PARTIES, INCLUDING THIRD PARTY SITES
                                          OR PLATFORM PROVIDERS OR OTHER USERS
                                          OF TODDLER TALK SERVICES.
                                          <ExtraPaddingBottom />
                                          Certain state and national laws do not
                                          allow some of the exclusion of
                                          liability or the warranty disclaimers
                                          set forth above. Therefore, the above
                                          limitations and disclaimers may not
                                          apply to you, and you may have
                                          additional rights.
                                          <ExtraPaddingBottom />
                                          <b>11. INDEMNITY</b>
                                          <ExtraPaddingBottom />
                                          If you misuse Toddler Talk Services,
                                          violate the law, or violate these
                                          Terms of Use, and your violation
                                          results in loss or damage or a claim
                                          or liability against us, you agree to
                                          indemnify, defend and hold us harmless
                                          from (which means you agree to
                                          compensate us for) that loss, damage,
                                          claim or liability, including our
                                          legal fees and expenses. We may take
                                          exclusive charge of the defense of any
                                          legal action for which you are
                                          required to indemnify us under this
                                          Section, at your expense. You agree to
                                          cooperate in our defense of these
                                          actions. We will use reasonable
                                          efforts to notify you of any claim for
                                          which you are obligated to indemnify
                                          us. This Section will apply even if
                                          you stop using Toddler Talk Services
                                          or your Toddler Talk Account is
                                          deleted.
                                          <ExtraPaddingBottom />
                                          <b>
                                            12. AGREEMENT TO ARBITRATE; CLASS
                                            ACTION WAIVER
                                          </b>
                                          <ExtraPaddingBottom />
                                          <b>
                                            PLEASE READ THIS SECTION CAREFULLY.
                                            IT AFFECTS YOUR RIGHTS AND INCLUDES
                                            WAIVER OF A TRIAL BY JURY IN A COURT
                                            AND OF THE ABILITY TO BRING ANY
                                            CLAIM ON BEHALF OF OTHERS.
                                          </b>
                                          <ExtraPaddingBottom />
                                          <b>Overview</b>
                                          <ExtraPaddingBottom />
                                          This Section 12 (i.e., the “Agreement
                                          to Arbitrate”) applies to the maximum
                                          extent permitted by applicable law. If
                                          the laws of your jurisdiction prohibit
                                          the application of provisions of this
                                          Section 12 notwithstanding Section 13
                                          below, those prohibited provisions
                                          will not apply to you. IF YOU LIVE IN
                                          THE EUROPEAN UNION, NONE OF THIS
                                          SECTION 12 APPLIES TO YOU.
                                          <ExtraPaddingBottom />
                                          In the event of any controversy or
                                          claim arising out of or relating to
                                          these Terms, including any question
                                          regarding its existence, validity,
                                          termination or breach thereof, the
                                          parties hereto shall consult and
                                          negotiate with each other and,
                                          recognizing their mutual interests,
                                          attempt to reach a satisfactory
                                          solution. Most user concerns can be
                                          resolved by contacting our customer
                                          support team at
                                          support@toddlertalk.com. In the
                                          unlikely event that we are unable to
                                          resolve your concerns and a dispute
                                          remains, this Section explains how you
                                          and we agree to resolve it. As
                                          explained in more detail below,{" "}
                                          <b>
                                            we each agree to resolve any dispute
                                            between us through binding
                                            arbitration or small claims court
                                            instead of in courts of general
                                            jurisdiction.
                                          </b>
                                          <ExtraPaddingBottom />
                                          <b>
                                            Agreement to Arbitrate; Exceptions
                                          </b>
                                          <ExtraPaddingBottom />
                                          If we cannot resolve our dispute
                                          through customer support,{" "}
                                          <b>
                                            YOU AND WE AGREE TO RESOLVE ALL
                                            DISPUTES AND CLAIMS BETWEEN US IN
                                            INDIVIDUAL BINDING ARBITRATION,
                                            INCLUDING CLAIMS CONCERNING ANY
                                            ASPECT OF THE RELATIONSHIP BETWEEN
                                            US, YOUR DECISION TO DOWNLOAD OR
                                            ACCESS A TODDLER TALK SERVICE, YOUR
                                            USE OF AN Toddler Talk SERVICE, ANY
                                            USER CONTENT, OR YOUR ACCOUNT. This
                                            Agreement to Arbitrate is intended
                                            to be interpreted broadly, and
                                            applies to all legal claims under
                                            any legal theory (whether based in
                                            contract, tort, statute, fraud,
                                            misrepresentation, or any other
                                            legal theory), and applies to any
                                            disputes or claims that you assert
                                            or that arise even after you stop
                                            using or delete your Toddler Talk
                                            Account for a Toddler Talk Service,
                                            stop using a Toddler Talk Service,
                                            or delete a Toddler Talk Service
                                            application from your mobile or
                                            other device. This Agreement to
                                            Arbitrate also applies to any claims
                                            that are currently the subject of a
                                            purported class action litigation in
                                            which you are not a member of a
                                            certified class. You and we agree
                                            that the arbitrator shall have the
                                            exclusive authority to resolve any
                                            dispute relating to the
                                            interpretation, applicability or
                                            enforceability of the terms or
                                            formation of this contract,
                                            including whether any dispute
                                            between us is subject to this
                                            Agreement to Arbitrate (i.e., the
                                            arbitrator will decide the
                                            arbitrability of any dispute) and
                                            whether all or any part of these
                                            terms are void or voidable.
                                          </b>
                                          <ExtraPaddingBottom />
                                          An arbitration is a proceeding before
                                          a neutral arbitrator, instead of
                                          before a judge or jury. Arbitration is
                                          less formal than a lawsuit in court,
                                          and provides more limited discovery.
                                          It follows different rules than court
                                          proceedings, and is subject to very
                                          limited review by courts. The
                                          arbitrator will issue a written
                                          decision and provide a statement of
                                          reasons if requested by either party.{" "}
                                          <b>
                                            YOU UNDERSTAND THAT YOU AND WE ARE
                                            GIVING UP THE RIGHT TO SUE IN COURT
                                            AND THE RIGHT TO HAVE A TRIAL BEFORE
                                            A JUDGE OR JURY.
                                          </b>
                                          <ExtraPaddingBottom />
                                          This Section 12, however, does not
                                          apply to the following types of claims
                                          or disputes, which you or we may bring
                                          in court in accordance with Section 13
                                          below:
                                          <ExtraPaddingBottom />
                                          <b>(1)</b> claims of infringement or
                                          other misuse of intellectual property
                                          rights, including such claims seeking
                                          injunctive relief; and
                                          <ExtraPaddingBottom />
                                          <b>(2)</b> claims for preliminary
                                          injunctive relief for violations of
                                          sections 4 and 6 herein. This Section
                                          does not prevent you from bringing
                                          your dispute to the attention of any
                                          federal, state, or local government
                                          agencies that can, if the law allows,
                                          seek relief from us for you. Also, any
                                          of us can bring a claim in small
                                          claims court either in San Diego
                                          County, California or the county where
                                          you live, or some other place we both
                                          agree on, if such claims meets all the
                                          requirements to be brought in that
                                          court. The Federal Arbitration Act
                                          applies to this Section 12. The
                                          arbitration will be governed by the
                                          Consumer Arbitration Rules (the
                                          “Rules”) of the American Arbitration
                                          Association (“AAA”) (including Rule
                                          1(g) of those Rules that provides for
                                          arbitration through the submission of
                                          documents only/desk arbitration where
                                          no disclosed claims or counterclaims
                                          exceed $25,000), as modified by these
                                          Terms of Use, The Rules are available
                                          at www.adr.org. The arbitrator will be
                                          bound by these Terms of Use.
                                          <ExtraPaddingBottom />
                                          To start an arbitration proceeding,
                                          use the form on AAA’s website
                                          (www.adr.org) or call the AAA at
                                          1-800-778-7879.
                                          <ExtraPaddingBottom />
                                          Any arbitration under this section
                                          that is required to take place in
                                          person will take place pursuant to the
                                          Rules, which provide that face-to-face
                                          proceedings be conducted at a location
                                          which is reasonably convenient to both
                                          parties with due consideration of
                                          their ability to travel and other
                                          pertinent circumstances. If your claim
                                          is for US$25,000 or less, we agree to
                                          reimburse your filing fee promptly
                                          upon being notified of the filing, or
                                          pay it for you if you are unable to
                                          pay it and we receive a written
                                          request from you. Also, if your claim
                                          is for US$25,000 or less, we agree to
                                          reimburse your share of the
                                          arbitration costs, including your
                                          share of arbitrator compensation, at
                                          the conclusion of the proceeding,
                                          unless the arbitrator determines your
                                          claims are frivolous or your costs are
                                          unreasonable as determined by the
                                          arbitrator. If you seek more than
                                          US$25,000, the arbitration costs,
                                          including arbitrator compensation,
                                          will be split between you and us
                                          according to the Rules. Irrespective
                                          of the amount you seek, neither party
                                          shall be entitled to have their
                                          attorneys’ fees or costs paid by the
                                          other party, provided, however, that
                                          either party may seek to recover their
                                          attorneys’ fees and costs in
                                          arbitration if the arbitrator
                                          determines that the other party's
                                          claims (or counterclaims) are
                                          frivolous or the other party's costs
                                          are unreasonable.
                                          <ExtraPaddingBottom />
                                          <b>For EU Users</b>
                                          <ExtraPaddingBottom />
                                          In the event of any controversy or
                                          claim arising out of or relating to
                                          these Terms, including any question
                                          regarding its existence, validity,
                                          termination or breach thereof, the
                                          parties hereto shall consult and
                                          negotiate with each other and,
                                          recognizing their mutual interests,
                                          attempt to reach a satisfactory
                                          solution. If they do not reach
                                          settlement within a period of 60 days,
                                          then, upon notice by any party to the
                                          other(s), any unresolved controversy
                                          or claim shall be settled by
                                          arbitration administered by the
                                          International Centre for Dispute
                                          Resolution (“ICDR”) in accordance with
                                          the provisions of its International
                                          Arbitration Rules. The place of
                                          arbitration shall be in London,
                                          England. The number of arbitrators
                                          shall be one. The language to be used
                                          in the arbitral proceedings shall be
                                          English. Except as may be required by
                                          law, neither a party nor its
                                          representatives may disclose the
                                          existence, content, or results of any
                                          arbitration hereunder without the
                                          prior written consent of Toddler Talk.
                                          <ExtraPaddingBottom />
                                          <b>Class Action Waiver</b>
                                          <ExtraPaddingBottom />
                                          For disputes arising between Toddler
                                          Talk and you, or any other user, that
                                          are subject to this Agreement to
                                          Arbitrate, you and we agree that we
                                          can only bring a claim against each
                                          other on an individual basis.{" "}
                                          <b>
                                            NEITHER YOU NOR WE CAN BRING A CLAIM
                                            AS A PLAINTIFF OR CLASS MEMBER IN A
                                            CLASS ACTION, CONSOLIDATED ACTION,
                                            OR REPRESENTATIVE ACTION. THE
                                            ARBITRATOR CANNOT COMBINE MORE THAN
                                            ONE PERSON’S CLAIM INTO A SINGLE
                                            CASE, AND CANNOT PRESIDE OVER ANY
                                            CONSOLIDATED, CLASS, OR
                                            REPRESENTATIVE ARBITRATION
                                            PROCEEDING, UNLESS WE BOTH AGREE
                                            OTHERWISE IN WRITING. THE
                                            ARBITRATOR’S DECISION OR AWARD IN
                                            ONE PERSON’S CASE CAN ONLY AFFECT
                                            THE PERSON WHO BROUGHT THE CLAIM,
                                            NOT OTHER USERS OF TODDLER TALK
                                            SERVICES, AND CANNOT BE USED TO
                                            DECIDE DISPUTES WITH OTHERS.
                                          </b>
                                          <ExtraPaddingBottom />
                                          If a court decides that this “Class
                                          Action Waiver” subsection is not
                                          enforceable or is invalid, then
                                          Section 12 shall cease to have effect,
                                          however, the remaining portions of the
                                          Terms and Conditions will remain in
                                          full force and effect.
                                          <b>Service of Process</b>
                                          <ExtraPaddingBottom />
                                          To initiate arbitration or any legal
                                          proceeding against Toddler Talk, you
                                          must serve initiating documents on
                                          Toddler Talk’s registered agent for
                                          service of process at: David
                                          Lizerbram, David Lizerbram &
                                          Associates, 3180 University Avenue,
                                          Suite 260, San Diego, California
                                          92104.
                                          <ExtraPaddingBottom />
                                          <b>Changes to this Section 12</b>
                                          <ExtraPaddingBottom />
                                          Notwithstanding any provision in these
                                          Terms to the contrary, we agree that
                                          if we make any future change to this
                                          Section 12, you may reject any such
                                          change by sending us written notice
                                          within 30 days of the change to:
                                          support@toddlertalk.com.
                                          <ExtraPaddingBottom />
                                          <b>13. APPLICABLE LAW.</b>
                                          <ExtraPaddingBottom />
                                          <b>For Users other than EU Users</b>
                                          <ExtraPaddingBottom />
                                          You agree that these Terms of Use
                                          shall be deemed to have been made and
                                          executed in the State of California,
                                          U.S.A. and that any dispute arising
                                          under the Terms of Use, as well as any
                                          other dispute or claim that may arise
                                          between you and us, shall be governed
                                          by and resolved in accordance with the
                                          laws of the State of California,
                                          without regard to conflict of law
                                          provisions or principals.
                                          <ExtraPaddingBottom />
                                          For claims not required to be
                                          arbitrated under Section 12, or in the
                                          event the arbitration provision in
                                          Section 12 is deemed unenforceable or
                                          voided, you agree that any claim
                                          asserted in any legal proceeding by
                                          you against us shall be commenced and
                                          maintained exclusively in any state or
                                          federal court located in San Diego
                                          County, California having subject
                                          matter jurisdiction with respect to
                                          the dispute between the parties and
                                          you hereby consent to the exclusive
                                          jurisdiction of such courts. In any
                                          dispute arising between us, the
                                          prevailing party will be entitled to
                                          attorneys’ fees and expenses.
                                          <ExtraPaddingBottom />
                                          <b>For EU Users</b>
                                          <ExtraPaddingBottom />
                                          You agree that these Terms of Use
                                          shall be governed by the laws of
                                          England, excluding the law of
                                          conflicts and the Convention on
                                          Contracts for the International Sale
                                          of Goods (CISG). However, where the
                                          laws of England provide a lower degree
                                          of consumer protection than the laws
                                          of your country of residence, the
                                          consumer protection laws of your
                                          country shall prevail. In any dispute
                                          arising between us, the prevailing
                                          party will be entitled to attorneys’
                                          fees and expenses.
                                          <ExtraPaddingBottom />
                                          <b>
                                            14. THIRD PARTY PROMOTIONS AND LINKS
                                          </b>
                                          <ExtraPaddingBottom />
                                          Toddler Talk Services may contain
                                          advertisements and other promotional
                                          content from third parties, including
                                          links to third party websites or
                                          vendors (collectively "Third Party
                                          Promotions "), some of which may
                                          invite you to participate in
                                          promotional offers in return for
                                          receiving optional services and/or
                                          In-Service Content. Some of these
                                          Third Party Promotions may charge
                                          separate fees, which are not included
                                          in any fees that you may pay to us.
                                          Toddler Talk may receive revenue or
                                          other compensation should you purchase
                                          goods or services from a third party
                                          from a link in the Toddler Talk
                                          Services. Any separate charges or
                                          obligations you incur in your dealings
                                          with such third parties are your
                                          responsibility. We make no
                                          representation or warranty regarding
                                          any content, goods and/or services
                                          contained in such Third Party
                                          Promotions and will not be liable for
                                          any claim relating thereto. We
                                          exercise no control over Third Party
                                          Promotions, and Toddler Talk has no
                                          responsibility for their content,
                                          goods, services, performance,
                                          operation, availability, business
                                          practices or policies. We are
                                          providing these Third Party Promotions
                                          to you only as a convenience, and we
                                          do not imply any endorsement or
                                          recommendation of their content,
                                          goods, or services. If you decide to
                                          access any of these Third Party
                                          Promotions, you do so entirely at your
                                          own risk. Third Party Promotions
                                          including third party websites are
                                          subject to their own terms and
                                          policies, including privacy and data
                                          gathering practices.
                                          <ExtraPaddingBottom />
                                          <b>
                                            15. OWNERSHIP; TRADEMARKS AND
                                            COPYRIGHTS
                                          </b>
                                          <ExtraPaddingBottom />
                                          All rights, title and interest in and
                                          to Toddler Talk Services (including
                                          without limitation any titles,
                                          content, computer code, themes,
                                          objects, concepts, artwork,
                                          animations, sounds, musical
                                          compositions, audio-visual effects,
                                          methods of operations, moral rights,
                                          documentation, in-Service chat
                                          transcripts, and recordings) are owned
                                          by Toddler Talk. Toddler Talk reserves
                                          all rights, including without
                                          limitation all intellectual property
                                          rights or other proprietary rights in
                                          connection with Toddler Talk Services.
                                          <ExtraPaddingBottom />
                                          The trademark “TODDLER TALK,” and any
                                          logos associated with such, are
                                          trademarks of Toddler Talk and its
                                          subsidiaries and affiliates, and all
                                          trademarks, logos and service marks
                                          (collectively, "Marks") displayed on
                                          or in Toddler Talk Services are either
                                          our property or the property of third
                                          parties. Similarly, all artwork and
                                          other protectable expression in
                                          Toddler Talk Services are either our
                                          property or the property of third
                                          parties and protected by copyright
                                          law. Marks, artwork, images, music,
                                          and any other protectable expression
                                          may not be copied, imitated or used
                                          without written permission from the
                                          owner.
                                          <ExtraPaddingBottom />
                                          <b>16. MISCELLANEOUS</b>
                                          <ExtraPaddingBottom />
                                          <b>Reviews</b>
                                          <ExtraPaddingBottom />
                                          At various times, we may provide
                                          reviews of products, services, or
                                          other resources. This may include
                                          reviews of books, services, and/or
                                          software applications. Any such
                                          reviews will represent the good-faith
                                          opinions of the author of such review.
                                          The products and services reviewed may
                                          be provided to Toddler Talk for free
                                          or at a reduced price as an incentive
                                          to provide a review.
                                          <ExtraPaddingBottom />
                                          Regardless of any such discounts, we
                                          will provide honest reviews of these
                                          products and/or services. You
                                          recognize that you should conduct your
                                          own due diligence and should not rely
                                          solely upon any reviews provided on
                                          this website.
                                          <ExtraPaddingBottom />
                                          We will disclose the existence of any
                                          discounts or incentives received in
                                          exchange for providing a review of a
                                          product. If you would like more
                                          information about any such discounts
                                          and incentives, send an email to
                                          support@toddlertalk.com that includes
                                          the title of the reviewed product as
                                          the subject line. We will respond via
                                          email and disclose any incentives or
                                          discounts we received in association
                                          with any such review.
                                          <ExtraPaddingBottom />
                                          <b>Affiliate Links</b>
                                          <ExtraPaddingBottom />
                                          From time to time, Toddler Talk
                                          participates in affiliate marketing
                                          and may allow affiliate links to be
                                          included on some of our pages. This
                                          means that we may earn a commission
                                          if/when you click on or make purchases
                                          via affiliate links.
                                          <ExtraPaddingBottom />
                                          As a policy, Toddler Talk will only
                                          affiliate with products, services,
                                          coaches, consultants, and other
                                          experts that we believe will provide
                                          value to our customers and followers.
                                          <ExtraPaddingBottom />
                                          Toddler Talk will inform you when one
                                          of the links constitutes an affiliate
                                          link.
                                          <ExtraPaddingBottom />
                                          You recognize that it remains your
                                          personal responsibility to investigate
                                          whether any affiliate offers are right
                                          for your business and will benefit
                                          you. You will not rely on any
                                          recommendation, reference, or
                                          information provided by Toddler Talk
                                          but will instead conduct your own
                                          investigation and will rely upon your
                                          investigation to decide whether to
                                          purchase the affiliate product or
                                          service.
                                          <ExtraPaddingBottom />
                                          <b>No Endorsements</b>
                                          <ExtraPaddingBottom />
                                          From time to time, Toddler Talk will
                                          refer to other products, services,
                                          coaches, consultants, and/or experts.
                                          Any such reference is not intended as
                                          an endorsement or statement that the
                                          information provided by the other
                                          party is accurate. Toddler Talk
                                          provides this information as a
                                          reference for users. It is your
                                          responsibility to conduct your own
                                          investigation and make your own
                                          determination about any such product,
                                          service, coach, consultant, and/or
                                          expert.
                                          <ExtraPaddingBottom />
                                          <b>Testimonials</b>
                                          ​ <ExtraPaddingBottom />
                                          At various places on this website, you
                                          may find testimonials from clients and
                                          customers of the products and services
                                          offered on this website or by Toddler
                                          Talk. The testimonials are actual
                                          statements made by clients and/or
                                          customers and have been truthfully
                                          conveyed on this website.
                                          <ExtraPaddingBottom />
                                          Although these testimonials are
                                          truthful statements about results
                                          obtained by these clients and/or
                                          customers, the results obtained by
                                          these clients and/or customers are not
                                          necessarily typical. You specifically
                                          recognize and agree that the
                                          testimonials are not a guarantee of
                                          results that you or anyone else will
                                          obtain by using any products or
                                          services offered on this website or by
                                          Toddler Talk.
                                          <ExtraPaddingBottom />
                                          <b>Additional Terms</b>
                                          <ExtraPaddingBottom />
                                          Except as otherwise expressly set
                                          forth in these Terms of Use, in the
                                          event that any provision of these
                                          Terms of Use shall be held by a court
                                          or other tribunal of competent
                                          jurisdiction to be unenforceable, such
                                          provision will be enforced to the
                                          maximum extent permissible and the
                                          remaining portions of these Terms of
                                          Use shall remain in full force and
                                          effect. These Terms of Use constitute
                                          and contain the entire agreement
                                          between you and us with respect to the
                                          subject matter hereof and supersedes
                                          any prior oral or written agreements.
                                          You agree that these Terms of Use are
                                          not intended to confer and do not
                                          confer any rights or remedies upon any
                                          person other than the parties to these
                                          Terms of Use.
                                          <ExtraPaddingBottom />
                                          If we provide a translated version of
                                          these Terms of Use or any other terms
                                          or policy incorporated or referenced
                                          in these Terms of Use, it is for
                                          informational purposes only. If the
                                          translated version means something
                                          different than the English version,
                                          then the English meaning will be the
                                          one that applies.
                                          <ExtraPaddingBottom />
                                          If we do not enforce a provision of
                                          these Terms of Use, that does not
                                          waive our right to do so later. And,
                                          if we do expressly waive a provision
                                          of these Terms of Use, that does not
                                          mean it is waived for all time in the
                                          future. Any waiver must be in writing
                                          and signed by both you and us to be
                                          legally binding.
                                          <ExtraPaddingBottom />
                                          Toddler Talk is subject to existing
                                          laws and legal process, and we may
                                          comply with law enforcement or
                                          regulatory requests or requirements
                                          notwithstanding any term to the
                                          contrary in these Terms of Use.
                                          <ExtraPaddingBottom />
                                          We may notify you via postings on our
                                          website or via e-mail or via any other
                                          contact information you provide to us.
                                          Except as otherwise expressly provided
                                          herein, all notices given by you or
                                          required from you under these Terms of
                                          Use or the Toddler Talk Privacy Policy
                                          shall be in writing and addressed to:
                                          Toddler Talk, Inc. Attn: Legal. Any
                                          notices that you provide that do not
                                          comply with these requirements shall
                                          have no legal effect.
                                          <ExtraPaddingBottom />
                                          Toddler Talk shall not be liable for
                                          any delay or failure to perform
                                          resulting from causes outside the
                                          reasonable control of Toddler Talk,
                                          including without limitation any
                                          failure to perform hereunder due to
                                          unforeseen circumstances or causes
                                          beyond Toddler Talk’s control, such as
                                          acts of God, war, terrorism, riots,
                                          embargoes, acts of civil or military
                                          authorities, fire, floods, accidents,
                                          strikes, or shortages of
                                          transportation facilities, fuel,
                                          energy, labor or materials.
                                          <ExtraPaddingBottom />
                                          <b>
                                            For Australia and New Zealand Users
                                            Only:
                                          </b>
                                          <ExtraPaddingBottom />
                                          Upon termination of your account,
                                          Toddler Talk will take all reasonable
                                          steps to destroy or de-identify your
                                          personal information. You acknowledge
                                          that some information about you may be
                                          retained where required by law or for
                                          data back-up purposes.
                                          <ExtraPaddingBottom />
                                          <b>For European Union Users Only:</b>
                                          <ExtraPaddingBottom />
                                          If you reside in the European Union,
                                          you acknowledge that you will no
                                          longer have the right to cancel under
                                          the EU's Consumer Rights Directive (or
                                          implementing legislation in the
                                          country where you are located) once we
                                          start to supply you with the
                                          In-Service Item.
                                          <ExtraPaddingBottom />
                                          <b>CONTACT INFORMATION</b>
                                          <ExtraPaddingBottom />
                                          This website is operated and provided
                                          by Toddler Talk, Inc. If you have any
                                          questions, comments or concerns
                                          regarding our Terms of Use, please
                                          send an e-mail to
                                          info@toddlertalk.com.
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
